@import '@aurora/shared-client/styles/_variables.pcss';

.lia-cover-image-wrap {
  display: flex;
  position: relative;
  justify-content: center;

  .lia-cover-image {
    height: 100%;
    width: 100%;
    max-width: var(--lia-container-max-width);
    object-fit: cover;
  }

  &.lia-width-standard {
    grid-area: 1/1/3/2;
    height: 500px;

    .lia-cover-image {
      border-radius: var(--lia-bs-border-radius) var(--lia-bs-border-radius) 0 0;

      @media (--lia-breakpoint-down-xs) {
        border-radius: 0;
      }
    }
  }

  &.lia-width-wide {
    grid-area: 1/1/3/2;
    height: 700px;

    .lia-cover-image {
      position: absolute;
      max-width: 100vw;
      width: 100vw;
      height: 700px;
    }
  }

  &.lia-is-page-content-width {
    .lia-cover-image {
      max-width: 100%;
      width: 100%;
    }
  }

  &.lia-possition-overlay {
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0) 20%,
        hsla(var(--lia-bs-black-h), var(--lia-bs-black-s), var(--lia-bs-black-l), 0.5) 100%
      );
    }

    &.lia-width-standard:after {
      left: 0;
      right: 0;
    }

    &.lia-width-wide:after {
      width: 100vw;
    }
  }

  &.lia-possition-top.lia-width-standard.lia-cover-image {
    border-radius: unset;
  }

  @media (--lia-breakpoint-down-md) {
    &.lia-width-standard {
      height: 300px;

      &.lia-possition-overlay {
        height: 375px;
      }
    }

    &.lia-width-wide {
      height: 300px;

      &.lia-possition-overlay {
        height: 375px;
      }
      .lia-cover-image {
        height: 300px;
      }
    }

    &.lia-possition-overlay .lia-cover-image {
      height: 375px;
    }
  }

  @media (--lia-breakpoint-down-xs) {
    &.lia-width-standard.lia-cover-image {
      border-radius: unset;
    }
  }
}

.lia-has-cover-video {
  background: radial-gradient(circle, var(--lia-bs-black) 0%, var(--lia-bs-primary) 100%);
  border-radius: var(--lia-bs-border-radius) var(--lia-bs-border-radius) 0 0;
  overflow: hidden;

  &.lia-is-zoom-meeting {
    width: 100%;
    height: 650px;
  }

  @media (--lia-breakpoint-down-xs) {
    border-radius: unset;
  }
}
